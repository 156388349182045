import ImgComponent from "../../imgComponent"
import React from 'react'; 
const ContentPhoto = () => {
  return (
    <section
      className="elementor-element elementor-element-3132549 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id={3132549}
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-b05077a elementor-column elementor-col-100 elementor-top-column"
            data-id="b05077a"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap  elementor-element-populated">
              <div className="elementor-widget-wrap smooved">
                <div
                  className="elementor-element elementor-element-7308272 animation elementor-widget elementor-widget-image smooved"
                  data-id={7308272}
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="image.default"
                  style={{ opacity: 1 }}
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image width-gambar-news">
                      <ImgComponent
                        pmlImg="certificate.jpg"
                        className="image"
                      />
                      {/* <figure className="wp-caption">
												<img
													width={1440}
													height={960}
													src="https://themegoods-cdn-pzbycso8wng.stackpathdns.com/avante/wp-content/uploads/2019/04/rawpixel-783345-unsplash.jpg"
													className="attachment-full size-full"
													alt=""
													srcSet="https://themegoods-cdn-pzbycso8wng.stackpathdns.com/avante/wp-content/uploads/2019/04/rawpixel-783345-unsplash.jpg 1440w, https://themegoods-cdn-pzbycso8wng.stackpathdns.com/avante/wp-content/uploads/2019/04/rawpixel-783345-unsplash-300x200.jpg 300w, https://themegoods-cdn-pzbycso8wng.stackpathdns.com/avante/wp-content/uploads/2019/04/rawpixel-783345-unsplash-768x512.jpg 768w, https://themegoods-cdn-pzbycso8wng.stackpathdns.com/avante/wp-content/uploads/2019/04/rawpixel-783345-unsplash-1024x683.jpg 1024w, https://themegoods-cdn-pzbycso8wng.stackpathdns.com/avante/wp-content/uploads/2019/04/rawpixel-783345-unsplash-700x466.jpg 700w, https://themegoods-cdn-pzbycso8wng.stackpathdns.com/avante/wp-content/uploads/2019/04/rawpixel-783345-unsplash-440x293.jpg 440w"
													sizes="(max-width: 1440px) 100vw, 1440px"
												/>
												<figcaption className="widget-image-caption wp-caption-text">
													Higher business values
												</figcaption>
											</figure> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContentPhoto
